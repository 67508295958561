import { render, staticRenderFns } from "./services.vue?vue&type=template&id=28bb5b84&scoped=true&"
import script from "./services.vue?vue&type=script&lang=ts&"
export * from "./services.vue?vue&type=script&lang=ts&"
import style0 from "./services.vue?vue&type=style&index=0&id=28bb5b84&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28bb5b84",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
